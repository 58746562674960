.Column {
    display: inline-block;
}

.Tile {
    width: 4vw;
    height: 4vw;
    margin: 10px 5px;
    border-radius: 3px;
    background-color: var(--tile-colour);
}

.Tile .icon {
    position: absolute;
    transform: translate(calc(4vw / 4), calc(4vw / 8));
}

.TileBorderBlocker {
    width: 3px;
    height: 4vw;
    transform: translate(calc(6vw + 9px), calc(-4vw - 10px));
    background-color: var(--background);
    position: absolute;
}
