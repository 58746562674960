.CarPool .DoubleWidth {
    display: flex;
}

.CarPool .DoubleWidth div {
    flex: 1;
}

.CarPool .DoubleWidth div:first-child {
    margin-right: 10px;
}

.CarPool .TruckSlots {
    width: fit-content;
    margin: auto;
}

.CarPool .Guide {
    margin: 30px 0 0 0;
    text-align: center;
}
