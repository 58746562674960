.Car {
    height: 3vw;
    border-radius: 3px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
}

.Car.Vertical {
    transform: rotate(-90deg);
}
