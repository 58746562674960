body {
    margin: 0;
    background: var(--background);
    color: var(--text-normal);
    font-family: 'Fira Mono', monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: var(--text-link);
}

a:hover {
    color: var(--text-link-hover);
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: #262930;
}

::-webkit-scrollbar-thumb {
    background-color: #1E2329;
}

::-webkit-scrollbar-track:hover {
    background-color: #343745;
}
