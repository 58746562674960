#Solution {
    width: 400px;
    padding: 0;
}

#Solution .Title {
    transform: translate(30px, -12px);
}

#Solution .Divider {
    width: 100%;
    transform: none;
}

#Solution .ButtonWrapper {
    text-align: center;
    margin: 2vw 0 2vw 0;
}

#Solution button {
    font-family: inherit;

    background-color: #FFFFFF10;
    border: none;
    border-radius: 3px;
    padding: 10px 25px;
    color: #D2D8DE;

    transition: color 100ms, background-color 100ms;
    cursor: pointer;
    outline: none;
}

#Solution button:hover {
    color: #E7EDEE;
    background-color: #FFFFFF18;
}

#Solution button:disabled {
    color: #6E7272;
    background-color: #FFFFFF05;
    cursor: not-allowed;
}

#Solution .Steps {
    margin: 2vw 0 0 0;
}

#Solution .StepsTitle {
    text-align: center;
    font-size: 18px;
    margin-bottom: 2vw;
}

#Solution .Steps .Text {
    text-align: center;
    font-size: 14px;
    margin-bottom: 2vw;
}

#Solution ol {
    max-height: 40vh;
    overflow: auto;
    margin-left: 2vw;
}

#Solution li {
    font-size: 14px;
    color: var(--text-link);
    margin: 5px 0;
    text-decoration: underline;
    cursor: pointer;
    transition: color 100ms;
    width: fit-content;
}

#Solution li:hover {
    color: var(--text-link-hover);
}

#Solution li.Active {
    color: var(--text-normal);
}

#Solution .StepButtons {
    margin-top: 2vw;
    display: flex;
}

#Solution .StepButtons button {
    flex: 1;
    border-radius: 0;
}

#Solution .StepButtons button:first-child {
    border-bottom-left-radius: 3px;
}

#Solution .StepButtons button:last-child {
    border-bottom-right-radius: 3px;
}
