h1.Title {
    font-size: 50px;
    margin: 0;
}

section {
    float: left;
    width: fit-content;

    border-style: solid;
    border-width: 1px;
    border-color: var(--tile-colour);
    border-radius: 5px;

    padding: 2vw;
    margin: 30px 0 30px 50px;
}

section .Title {
    width: fit-content;
    background-color: var(--background);
    font-size: 20px;
    transform: translate(30px, calc(-2vw - 12px));
    padding: 0 10px;
}

section .Divider {
    transform: translateX(-2vw);
    width: calc(100% + 4vw);
    height: 1px;
    background-color: var(--tile-colour);
}
